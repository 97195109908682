import PageComponent from "components/Page"
import { LOCALES } from "lib/constants"
import { apiVersion, dataset, projectId } from "lib/sanity.api"
import {
  getPageQuery,
  getReviewsQuery,
  getSettingsQuery,
  pageSlugsQuery,
} from "lib/sanity.queries"
import { GetStaticPaths, GetStaticProps, InferGetStaticPropsType } from "next"
import { serverSideTranslations } from "next-i18next/serverSideTranslations"
import { createClient } from "next-sanity"
import { lazy } from "react"
import { Page, Review, Settings } from "schemas"

const PreviewSuspense = lazy(() => import("components/shared/PreviewSuspense"))
const PreviewPage = lazy(() => import("components/PreviewPage"))

export const getStaticProps: GetStaticProps<
  {
    preview?: boolean
    token?: string
    data: Page
    settings: Settings
    locale: string
  },
  { slug: string[] },
  { token?: string }
> = async ({ params, locale, preview = false, previewData = {} }) => {
  const token = previewData?.token || null
  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn: preview,
    token: token || undefined,
  })

  let slug = params?.slug?.join("/")

  if (!slug || slug === "") slug = "home"

  const data = await client.fetch<Page>(getPageQuery(), { slug, locale })
  const settings = await client.fetch<Settings>(getSettingsQuery(), { locale })
  const reviews = await client.fetch<Review[]>(getReviewsQuery(), { locale })

  return {
    props: {
      preview,
      token,
      data: data || {},
      settings: settings || {},
      reviews: reviews || [],
      locale,
      ...(await serverSideTranslations(locale, ["common"])),
    },
    // If webhooks isn't setup then attempt to re-generate in 1 minute intervals
    // revalidate: process.env.SANITY_REVALIDATE_SECRET ? undefined : 60,
    revalidate: 60,
  }
}

export const getStaticPaths: GetStaticPaths = async ({ locales }) => {
  if (!projectId) return {} as ReturnType<GetStaticPaths>

  const client = createClient({
    projectId,
    dataset,
    apiVersion,
    useCdn: false,
  })

  const pageDataWithLocale = await Promise.all(
    locales.map(async locale => {
      const data = await client.fetch(pageSlugsQuery, { locale })

      return { data, locale }
    })
  )

  return {
    paths: pageDataWithLocale
      .map(({ data, locale }) =>
        data.map(({ slug }) => ({
          params: {
            slug: [slug],
          },
          locale,
        }))
      )
      .concat(LOCALES.map(locale => ({ params: { slug: [""] }, locale })))
      .flat(),
    fallback: "blocking" as const,
  }
  // return {
  //   paths: [],
  //   fallback: "blocking",
  // }
}

type Props = InferGetStaticPropsType<typeof getStaticProps>

function Index({ preview, token, data, settings, reviews, locale }: Props) {
  if (preview) {
    return (
      <PreviewSuspense
        fallback={
          <PageComponent
            preview
            loading
            data={data}
            settings={settings}
            extra={{ reviews }}
          />
        }>
        <PreviewPage
          token={token}
          slug={data?.slug}
          locale={locale}
          extra={{ reviews }}
        />
      </PreviewSuspense>
    )
  }

  return <PageComponent data={data} settings={settings} extra={{ reviews }} />
}

export default Index
