import Layout from "components/layout/MainLayout"
import ExtraContextProvider from "context/ExtraContext"
import SettingsContextProvider from "context/SettingsContext"
import renderBlock from "lib/renderBlock"
import dynamic from "next/dynamic"
import { useRouter } from "next/router"
import { Page, Review, Settings } from "schemas"

const Error404Page = dynamic(() => import("components/Error404Page"))

type Props = {
  preview?: boolean
  loading?: boolean
  data: Page
  settings: Settings
  extra: {
    reviews?: Review[]
  }
}

export default function PageComponent({
  preview,
  loading,
  data,
  settings,
  extra,
}: Props) {
  const router = useRouter()

  const page = data
  const slug = page?.slug

  if (!router.isFallback && !slug && !preview) {
    return <Error404Page settings={settings} />
  }

  return (
    <SettingsContextProvider settings={settings || {}}>
      <ExtraContextProvider extra={extra}>
        <Layout preview={preview} loading={loading} page={page}>
          {router.isFallback || (preview && !page) ? (
            <h1>Loading…</h1>
          ) : (
            <>
              <article>{page?.content?.map(renderBlock)}</article>
            </>
          )}
        </Layout>
      </ExtraContextProvider>
    </SettingsContextProvider>
  )
}
