import { createContext, useContext } from "react"
import { Review } from "schemas"

export const ExtraContext = createContext<{ reviews: Review[] }>({
  reviews: [],
})

export const useExtra = () => useContext(ExtraContext)

const ExtraContextProvider = ({ extra, children }) => (
  <ExtraContext.Provider value={extra}>{children}</ExtraContext.Provider>
)

export default ExtraContextProvider
