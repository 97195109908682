import dynamic from "next/dynamic"
import {
  bigBannerSchema,
  contactHeaderSchema,
  fullWidthBannerSchema,
  homeHeaderSchema,
  horizontalCards2Schema,
  horizontalCardsSchema,
  imageSchema,
  integrationCardsSchema,
  integrationIconsSchema,
  landingHeaderSchema,
  legalSchema,
  logosSchema,
  mediaText1Schema,
  mediaText2Schema,
  mediaText3Schema,
  mediaText4Schema,
  pricingCardsSchema,
  pricingShowcaseSchema,
  quoteSchema,
  reviewIconsSchema,
  simpleHeaderSchema,
  spacerSchema,
  statsInfoSchema,
  textBlockSchema,
  textSectionSchema,
  thankYouSchema,
  verticalCards2Schema,
  verticalCardsSchema,
  verticalMediaText1Schema,
  verticalMediaText2Schema,
  youtubeEmbedSchema,
} from "schemas"

const BigBanner = dynamic(() => import("components/BigBanner"))
const ContactHeader = dynamic(() => import("components/ContactHeader"))
const FullWidthBanner = dynamic(() => import("components/FullWidthBanner"))
const HomeHeader = dynamic(() => import("components/HomeHeader"))
const HorizontalCards = dynamic(() => import("components/HorizontalCards"))
const HorizontalCards2 = dynamic(() => import("components/HorizontalCards2"))
const Image = dynamic(() => import("components/Image"))
const IntegrationCards = dynamic(() => import("components/IntegrationCards"))
const IntegrationIcons = dynamic(() => import("components/IntegrationIcons"))
const LandingHeader = dynamic(() => import("components/LandingHeader"))
const MediaText1 = dynamic(() => import("components/MediaText1"))
const MediaText2 = dynamic(() => import("components/MediaText2"))
const MediaText3 = dynamic(() => import("components/MediaText3"))
const MediaText4 = dynamic(() => import("components/MediaText4"))
const PricingCards = dynamic(() => import("components/PricingCards"))
const PricingShowcase = dynamic(() => import("components/PricingShowcase"))
const Quote = dynamic(() => import("components/Quote"))
const ReviewIcons = dynamic(() => import("components/ReviewIcons"))
const SimpleHeader = dynamic(() => import("components/SimpleHeader"))
const StatsInfo = dynamic(() => import("components/StatsInfo"))
const TextSection = dynamic(() => import("components/TextSection"))
const ThankYou = dynamic(() => import("components/ThankYou"))
const Legal = dynamic(() => import("components/Legal"))
const VerticalCards = dynamic(() => import("components/VerticalCards"))
const VerticalCards2 = dynamic(() => import("components/VerticalCards2"))
const VerticalMediaText1 = dynamic(
  () => import("components/VerticalMediaText1")
)
const VerticalMediaText2 = dynamic(
  () => import("components/VerticalMediaText2")
)
const YoutubeEmbed = dynamic(() => import("components/YoutubeEmbed"))
const Logos = dynamic(() => import("components/Logos"))

const Spacer = dynamic(() => import("components/Spacer"))
const TextBlock = dynamic(() => import("components/block/TextBlock"))

export default function renderBlock(block, i) {
  const key = block._key || `${block._id}${i}`

  switch (block._type) {
    case homeHeaderSchema.name:
      return <HomeHeader key={key} {...block} />

    case landingHeaderSchema.name:
      return <LandingHeader key={key} {...block} />

    case simpleHeaderSchema.name:
      return <SimpleHeader key={key} {...block} />

    case contactHeaderSchema.name:
      return <ContactHeader key={key} {...block} />

    case quoteSchema.name:
      return <Quote key={key} {...block} />

    case fullWidthBannerSchema.name:
      return <FullWidthBanner key={key} {...block} />

    case bigBannerSchema.name:
      return <BigBanner key={key} {...block} />

    case integrationIconsSchema.name:
      return <IntegrationIcons key={key} {...block} />

    case statsInfoSchema.name:
      return <StatsInfo key={key} {...block} />

    case mediaText1Schema.name:
      return <MediaText1 key={key} {...block} />

    case mediaText2Schema.name:
      return <MediaText2 key={key} {...block} />

    case mediaText3Schema.name:
      return <MediaText3 key={key} {...block} />

    case mediaText4Schema.name:
      return <MediaText4 key={key} {...block} />

    case verticalMediaText1Schema.name:
      return <VerticalMediaText1 key={key} {...block} />

    case verticalMediaText2Schema.name:
      return <VerticalMediaText2 key={key} {...block} />

    case verticalCardsSchema.name:
      return <VerticalCards key={key} {...block} />

    case verticalCards2Schema.name:
      return <VerticalCards2 key={key} {...block} />

    case horizontalCardsSchema.name:
      return <HorizontalCards key={key} {...block} />

    case horizontalCards2Schema.name:
      return <HorizontalCards2 key={key} {...block} />

    case integrationCardsSchema.name:
      return <IntegrationCards key={key} {...block} />

    case reviewIconsSchema.name:
      return <ReviewIcons key={key} {...block} />

    case textBlockSchema.name:
      return <TextBlock key={key} {...block} />

    case textSectionSchema.name:
      return <TextSection key={key} {...block} />

    case pricingShowcaseSchema.name:
      return <PricingShowcase key={key} {...block} />

    case pricingCardsSchema.name:
      return <PricingCards key={key} {...block} />

    case legalSchema.name:
      return <Legal key={key} {...block} />

    case thankYouSchema.name:
      return <ThankYou key={key} {...block} />

    case youtubeEmbedSchema.name:
      return <YoutubeEmbed key={key} {...block} />

    case logosSchema.name:
      return <Logos key={key} {...block} />

    case imageSchema.name:
      // eslint-disable-next-line jsx-a11y/alt-text
      return <Image key={key} {...block} />

    case spacerSchema.name:
      return <Spacer key={key} {...block} />

    default:
      return `Error! Unknown type: ${block._type}`
  }
}
